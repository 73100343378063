import { Injectable } from '@angular/core';

/**
 * Service to be used App wide which serves useable defined constants
 * for named variables to be used with AppState
 * and for event names to be used to GlobalState
 */
@Injectable({
  providedIn: 'root',
})
export class AppVars {
  public static USER_ACCOUNT = 'USER_ACCOUNT';
  public static ORG_CODE = 'ORG_CODE';
  public static ORG_NAME = 'ORG_NAME';
  public static PLAINTIFF_CODE = 'PLAINTIFF_CODE';
  public static SELECTED_PLAINTIFF_KEY = 'SELECTED_PLAINTIFF_KEY';
  public static SELECTED_PLAINTIFF = 'SELECTED_PLAINTIFF';
  public static SELECTED_APPOINTMENT_KEY = 'SELECTED_APPOINTMENT_KEY';
  public static SELECTED_ORDER_KEY = 'SELECTED_ORDER_KEY';
  public static SELECTED_APPOINTMENT = 'SELECTED_APPOINTMENT';
  public static ACTIVE_DASHBOARD_TAB = 'ACTIVE_DASHBOARD_TAB_INDEX';
  public static ACTIVE_PLAINTIFFS_ONLY = 'ACTIVE_PLAINTIFFS_ONLY';

  constructor() {}
}
