import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../auth.service';
import { User } from '../auth.user';
import { AppState } from './../../services/app.service';
import { AppVars } from './../../services/app.vars';

@Component({
  selector: 'mlc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  userAccountSubscription: Subscription;

  email: string;
  password: string;
  submitted = false;
  showProgress = false;
  ackMessage = '';
  errorMessage = '';
  verificationFailed = false;

  mainAppPath = '/app';
  joinOrgPath = '/auth/join';

  // TODO: Move all the 'User Feedback / Error Messages' into bundle for i18N implementation
  constructor(
    private authService: AuthService,
    private _as: AppState,
    private _av: AppVars,
    // private _gs: GlobalState,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.user.subscribe((user: User) => {
      console.log('[Login Component]: init() user = ', user);
      if (user) {
        // TODO: Check email verification
        // if (this.authService.emailVerified) {
        this._as.set(AppVars.USER_ACCOUNT, user);
        this._as.set(AppVars.ORG_CODE, user.orgCode);
        const nextRoute = user.approved ? this.mainAppPath : this.joinOrgPath;
        // console.log('[Login Component]: getUserAccount() - now AppState UserAccount = ', this._as.get(AppVars.USER_ACCOUNT));
        console.log('[Login Component]: init() next route path = ', nextRoute);
        this.router.navigate([nextRoute]).then((ok) => console.log('Next Route: ', nextRoute, ' OK: ', ok));
        // });
        // } else {
        //   this.verificationFailed = true;
        //   this.errorMessage = 'Your registered email is not yet verified!';
        //   this.showProgress = false;
        //   this.submitted = false;
        // }
      }
    });
  }

  reset(): void {
    this.email = '';
    this.password = '';
    this.ackMessage = '';
    this.errorMessage = '';
    this.submitted = false;
    this.showProgress = false;
    this.verificationFailed = false;
  }

  submit(): void {
    if (!this.email || !this.password) {
      return;
    }
    this.showProgress = true;
    console.log('Email: ', this.email);
    this.submitted = true;
    this.errorMessage = '';
    this.ackMessage = '';

    this.showProgress = true;
    this.authService
      .emailLogin(this.email, this.password)
      .then(() => {
        this.showProgress = false;
      })
      .catch((err) => {
        this.errorMessage = 'Cannot login, please check your credentials or network connection.';
        console.log('Login error: ', err.message);
        this.showProgress = false;
        this.submitted = false;
      });
  }

  resendVerificationEmail(): void {
    this.showProgress = true;
    this.submitted = true;
    this.errorMessage = '';
    this.ackMessage = '';
    // this.authService
    //   .resendVerificationEmail()
    //   .then(() => {
    //     this.ackMessage = 'Verification link is sent to your registered email.';
    //     this.showProgress = false;
    //     this.submitted = false;
    //     this.authService.logout();
    //   })
    //   .catch(err => {
    //     this.errorMessage = 'Cannot process your request, please contact your admin.';
    //     console.log('Verification Email Resend error: ', err.message);
    //     this.showProgress = false;
    //     this.submitted = false;
    //   });
  }

  /**
   * Tear down and unsubscribe to the User Account
   */
  ngOnDestroy() {
    if (this.userAccountSubscription) {
      this.userAccountSubscription.unsubscribe();
    }
  }
}
