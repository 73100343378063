<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="20px" class="auth-box">
  <!--Header-->
  <div fxFlex="10" fxLayoutAlign="center center" class="header"></div>

  <!--Auth Child Component Outlet-->
  <div class="auth-content" fxFlex>
    <router-outlet></router-outlet>
  </div>

  <!--Footer-->
  <footer fxFlex="10" class="footer">
    <div class="copyright">© {{ dateValue | date: 'yyyy' }} ACCIDENT MATE All rights reserved.</div>
  </footer>
</div>
<div class="shadow-overlay"></div>
