<!-- Sign Up / Register Card -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="!showTos && !ackMessage">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between">
    <div class="card-title" fxFlex="40" fxLayoutAlign="start center">Sign Up</div>
    <div fxFlex fxLayoutAlign="end center"><a mat-button routerLink="/auth/login">Have an account? Sign in here...</a></div>
  </mat-card-header>
  <mat-card-content fxLayout="column">
    <div fxFlex="25">
      <mat-form-field class="full-width">
        <input matInput type="email" name="email" placeholder="Email Address" [(ngModel)]="email">
        <mat-icon matPrefix>mail_outline</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="25">
      <mat-form-field class="full-width">
        <input matInput type="password" name="password" placeholder="Password" [(ngModel)]="password">
        <mat-icon matPrefix>lock_outline</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="25">
      <mat-form-field class="full-width">
        <input matInput type="password" name="rePassword" placeholder="Confirm Password" [(ngModel)]="rePassword">
        <mat-icon matPrefix>lock</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="15">
      <mat-checkbox color="primary" [(ngModel)]="agreed">I agree to the Terms of Service</mat-checkbox>
      <a mat-icon-button (click)="toggleTos()" color="primary"><mat-icon color="primary">info</mat-icon></a>
    </div>
    <div fxFlex="10">
      <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center">
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="submitted || !email || !password || password != rePassword || !agreed">REGISTER</button>
    <button mat-button (click)="reset()">RESET</button>
  </mat-card-actions>
</mat-card>
<mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>

<!-- TODO: Separate the ToS into its own page and draw content from a resource file -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="showTos">
  <mat-card-header>
    <div class="card-title" fxLayoutAlign="center center">Terms of Service</div>
  </mat-card-header>
  <mat-card-content>
    <div style="overflow-y:auto; height:200px;">
      <p>Terms of Service...</p>
      <p>All rights reserved. MedLegalConnect.</p>
    </div>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center">
    <button mat-button (click)="toggleTos()">BACK</button>
  </mat-card-actions>
</mat-card>

<!-- Post completion of user registration show acknowledgement -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="ackMessage">
  <mat-card-header>
    <mat-icon matCardAvatar color="primary">check_circle</mat-icon>
    <div class="card-title">Confirmation</div>
  </mat-card-header>
  <mat-card-content>
    <div>{{ackMessage}}</div>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center">
    <button mat-raised-button color="primary" (click)="done()">OK</button>
  </mat-card-actions>
</mat-card>
