<!-- Sign In / Login card  -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="!verificationFailed">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px" fxFlex>
    <img src="/assets/images/ol_logo_full_v.svg" alt="OrganizeLAW" width="160px" />
    <mat-card-title color="primary">SIGN IN</mat-card-title>
  </div>
  <!-- </mat-card-header> -->

  <mat-card-content fxLayout="column">
    <div fxFlex="40">
      <mat-form-field appearance="outline" fxFlex>
        <input matInput type="email" name="email" placeholder="Email Address" [(ngModel)]="email" />
        <mat-icon matPrefix>mail_outline</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="40">
      <mat-form-field appearance="outline" fxFlex>
        <input matInput type="password" name="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="submit()" />
        <mat-icon matPrefix>lock_outline</mat-icon>
      </mat-form-field>
    </div>
    <div fxFlex="20">
      <p *ngIf="ackMessage || errorMessage">
        {{ ackMessage }}
        <mat-error> {{ errorMessage }}</mat-error>
        <button *ngIf="verificationFailed" mat-button (click)="resendVerificationEmail()">Resend verification email?</button>
      </p>
    </div>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="end center">
    <span fxFlex fxLayoutAlign="start center">
      <a routerLink="/auth/reset-password" mat-button>Forgot Password?</a>
    </span>
    <button mat-raised-button color="primary" (click)="submit()">SIGN IN</button>
    <button mat-button (click)="reset()">RESET</button>
  </mat-card-actions>
</mat-card>

<!-- Email verification failed -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="verificationFailed">
  <mat-card-header>
    <mat-icon matCardAvatar color="warn">error</mat-icon>
    <div class="card-title">Verification</div>
  </mat-card-header>
  <mat-card-content>
    <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
    <p *ngIf="ackMessage; else instructions">{{ ackMessage }}</p>
    <ng-template #instructions>Please click RESEND to get verification email.</ng-template>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center">
    <button mat-raised-button color="primary" (click)="reset()" *ngIf="ackMessage; else resend">OK</button>
    <ng-template #resend>
      <button mat-raised-button color="primary" (click)="resendVerificationEmail()">RESEND</button>
      <button mat-button (click)="reset()">CANCEL</button>
    </ng-template>
  </mat-card-actions>
</mat-card>

<!-- Progress Indicator -->
<mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>
