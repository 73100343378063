import { Component, OnInit } from '@angular/core';

import { AuthService } from './../auth.service';

@Component({
  selector: 'mlc-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  email: string;
  password: string;
  rePassword: string;
  fullName: string;
  submitted = false;
  showProgress = false;
  invalid = true; // By default the form is invalid
  agreed = false;
  ackMessage = '';
  errorMessage = '';
  showTos = false;

  constructor(private auth: AuthService) { }

  ngOnInit() {
  }

  reset(): void {
    this.email = '';
    this.password = '';
    this.rePassword = '';
    this.fullName = '';
    this.invalid = true;
    this.agreed = false;
    this.ackMessage = '';
    this.errorMessage = '';
  }

  submit(): void {
    console.log('Email: ', this.email);
    if (this.isValid()) {
      this.submitted = true;
      this.showProgress = true;
      this.ackMessage = '';
      this.errorMessage = '';
      this.rePassword = '';
      this.auth.createNewUser(this.email, this.password)
        .then(() => {
          this.ackMessage = 'User with email: ' + this.email
            + ' is successfully created. Please check your email and verify to activate the account.';
          this.showProgress = false;
        })
        .catch((err) => {
          this.errorMessage = err.message;
          console.log('New User Registration: ', this.email, this.errorMessage);
          this.submitted = false;
          this.showProgress = false;
          this.password = '';
          this.rePassword = '';
        });
    } else {
      this.errorMessage = 'Error: Please check your submission.';
      this.showProgress = false;
    }
  }

  toggleTos(): void {
    this.showTos = !this.showTos;
  }

  done(): void {
    this.auth.logout();
  }

  private isValid(): boolean {
    if (this.password && this.email && (this.password === this.rePassword) && this.agreed) {
      return true;
    }
    return false;
  }

}
