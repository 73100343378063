<div class="heading">Just one more step...</div>

<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="!requestDate">

  <mat-card-header fxLayout="row" fxLayoutAlign="space-between">
    <div class="card-title" fxFlex fxLayoutAlign="start center">Join Your Attorney Organization</div>
    <div fxFlex="30" fxLayoutAlign="end center"><button mat-button color="warn" (click)="logout()">LOGOUT</button></div>
  </mat-card-header>

  <mat-card-content fxLayout="column">
    <div fxFlex="20">Please use the ORG CODE you received by email to join. This is necessary to set up your account.</div>
    <div fxFlex="50">
      <mat-form-field class="full-width">
        <input matInput type="text" name="orgCode" placeholder="Org Code" [(ngModel)]="orgCode" (keyup.enter)="submit()">
        <mat-icon matPrefix>vpn_key</mat-icon>
      </mat-form-field>
    </div>

    <div fxFlex="30">
      <!-- <p *ngIf="ackMessage || errorMessage">{{ackMessage}} -->
      <mat-error *ngIf="errorMessage">{{errorMessage}}</mat-error>
      <!-- </p> -->
      <!-- <p>Status:<span *ngIf="requestDate; else newRequest"> PENDING APPROVAL (Please Logout now! Login once your account is activated)</span>
        <span *ngIf="requestDate">(Request Date: {{requestDate | date: "MMM dd, yyyy"}})</span>
        <ng-template #newRequest><span> New Request</span></ng-template>
      </p> -->
    </div>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="end center">
    <button mat-raised-button color="primary" (click)="submit()">{{submitButtonName}}</button>
  </mat-card-actions>

</mat-card>
<mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>

<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="orgCode && requestDate">
  <mat-card-header>
    <mat-icon matCardAvatar>vpn_key</mat-icon>
    <div class="card-title">Pending Approval</div>
  </mat-card-header>
  <mat-card-content>
    <p>Organization Code: <i>{{orgCode}}</i></p>
    <p>Request Date: <i>{{requestDate | date: "MMM dd, yyyy"}}</i></p>
    <p>Your request has been registered and pending for approval.</p>
    <p>Try logging in after your account is approved and activated.</p>
  </mat-card-content>
  <mat-card-actions fxLayoutAlign="end center">
    <p><small><i>Please contact your account administrator, if you did not expect this.</i></small></p>
    <div fxFlex></div>
    <button mat-button (click)="requestDate=null">EDIT</button>
    <button mat-raised-button color="primary" (click)="logout()">OK</button>
  </mat-card-actions>
</mat-card>
