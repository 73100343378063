import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { User } from './auth.user';

// import 'firebase/auth';

// import { NotifyService } from './../notify.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // fbAuth = firebase.auth();
  user: Observable<User | null>;
  uuid: string;
  emailVerified: boolean;
  orgCode: string;
  userEmail: string;
  userName: string;

  private usersPath = 'attorney-users';
  private orgRoot = 'attorney-org';

  constructor(
    private afAuth: AngularFireAuth,
    private afDb: AngularFireDatabase,
    private afs: AngularFirestore,
    private router: Router // private notify: NotifyService
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap((u) => {
        if (u) {
          this.uuid = u.uid;
          this.emailVerified = u.emailVerified;
          console.log('[AuthService] authState uid = ', u.uid);
          return this.afs.doc<User>(`attorney-users/${u.uid}`).valueChanges();
        } else {
          return observableOf(null);
        }
      })
    );
    this.user.subscribe((user) => {
      if (user) {
        this.orgCode = user.orgCode;
        this.userEmail = user.email;
        this.userName = user.displayName;
        console.log('[AuthService] setting orgCode=', this.orgCode);
      } else {
        this.uuid = null;
        this.userEmail = null;
        this.orgCode = null;
        this.userName = null;
      }
    });
  }

  /**
   * Create a User / SignUp the user for service
   * @param email Valid email ID of the user
   * @param password secret password for auth
   */
  public emailSignUp(email: string, password: string) {
    // return this.fbAuth
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((res) => {
        console.log('[Core AuthService] emailSignUp(): res = ', res);
        const newUser = {
          uid: res.user.uid,
          email: res.user.email,
        };
        console.log('[Core AuthService] emailSignUp(): new user = ', newUser);
        return this.updateUserData(newUser); // if using firestore
      })
      .catch((error) => this.handleError(error));
  }

  /**
   * Create a User / SignUp the user for service
   * @param email Valid email ID of the user
   * @param password secret password for auth
   */
  public createNewUser(email: string, password: string): Promise<void> {
    return this.emailSignUp(email, password);
  }

  /**
   * SignIn the user for service
   * @param email Valid email ID of the user
   * @param password secret password for auth
   */
  public emailLogin(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password).then((user) => {
      console.log('[Core AuthService] emailLogin(): user = ', user);
      // TODO: Audit log every successful login
    });
  }

  // Sends email allowing user to reset password
  public resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email).catch((error) => this.handleError(error));
  }

  // TODO: Use a strongtyped Profile Object Class
  // public updateName(name: string): Promise<any> {
  //   return this.fbAuth.currentUser.updateProfile({
  //     displayName: name,
  //     photoURL: '',
  //   });
  // }

  // The Promises are chained to handle
  // both the errors while updating Email & Sending mail verification
  // calling function has to catch the errors
  // TODO: Handle forced Logout and invalidating previous email
  // public updateEmail(newEmail: string): Promise<any> {
  //   // console.log('[Core AuthService] Attempting email update for: ', this.afAuth.currentUser.email, ' with ', newEmail);
  //   return new Promise((resolve, reject) => {
  //     this.fbAuth.currentUser.updateEmail(newEmail).then(() => {
  //       // TODO: Check this ->Now the emailVerified should be false
  //       this.fbAuth.currentUser.sendEmailVerification().then(() => {
  //         resolve('Email updated & verification email sent!');
  //         this.logout();
  //       });
  //     });
  //   });
  // }

  // TODO: Build consistency in handling Promises internally to manage 'Login' authenticity
  // public updatePassword(password: string): Promise<any> {
  //   if (password && password.length > 0) {
  //     return this.fbAuth.currentUser.updatePassword(password);
  //     // TODO: force logout the user when change of password is successfull
  //   } else {
  //     throw new Error('Error: Could not update password now. Try again later.');
  //   }
  // }

  // Sign Out or Log out
  public signOut() {
    // this.fbAuth.signOut();
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/auth/login']);
    });
    console.log('[AuthService] signOut(): now to navigate back to /auth/login');
  }

  public logout() {
    this.signOut();
  }

  // Resend verification email to the current user's email ID
  // public resendVerificationEmail(): Promise<any> {
  //   return this.fbAuth.currentUser.sendEmailVerification();
  // }

  //
  public getUserAccount(uid?: string): Observable<any> {
    const uidForPath = uid ? uid : this.uuid;
    const userNodePath = `${this.usersPath}/${uidForPath}`;
    console.log('[Core AuthService] getUserAccount(): userNodePath = ', userNodePath);
    if (!uidForPath) {
      throw new Error('[AuthService] - missing UID to get User Account!');
    }
    return this.afs.doc<User>(userNodePath).valueChanges();
  }

  //
  public updateUserAccount(accData: any): Promise<void> {
    const userNodePath = `${this.usersPath}/${this.uuid}`;
    return this.afs.doc(userNodePath).update(accData);
  }

  //
  public sendPasswordResetEmail(email: string): Promise<void> {
    return (
      this.afAuth
        .sendPasswordResetEmail(email)
        // .then(() => this.notify.update('Password update email sent', 'info'))
        .catch((error) => this.handleError(error))
    );
  }

  /**
   * Get the Organization metadata
   * @param orgCode Organization Code to get the Org Data
   */
  public getOrgData(orgCode: string): Observable<any> {
    const orgPath = `attorney-orgs/${this.orgRoot}-${orgCode}`;
    return this.afs.doc(orgPath).valueChanges();
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    // this.notify.update(error.message, 'error');
  }

  // Sets user data to firestore after succesful login
  private updateUserData(user: User) {
    console.log('[Core AuthService] updateUserData(): user = ', user);
    if (!user) {
      return;
    }
    const userRefPath = `/${this.usersPath}/${user.uid}`;
    console.log('[Core AuthService] updateUserData(): userRefPath = ', userRefPath);
    const data: User = {
      uid: user.uid,
      email: user.email || null,
      displayName: user.displayName || user.email || ' - ',
      photoURL: user.photoURL || null,
      orgCode: user.orgCode || null,
      approved: user.approved || false,
    };
    console.log('[Core AuthService] updateUserData(): user data = ', data);
    return this.afs
      .doc(userRefPath)
      .set(data)
      .then(() => {
        // this.resendVerificationEmail();
      });
  }
}
