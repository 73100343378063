<!-- Reset Password card  -->
<mat-card fxLayout="column" fxLayoutGap="40px" *ngIf="!ackMessage; else showAcknowledgement">
  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="16px" fxFlex>
    <img src="/assets/images/ol_logo_full_v.svg" alt="OrganizeLAW" width="160px" />
    <mat-card-title color="primary">RESET PASSWORD</mat-card-title>
  </div>

  <mat-card-content fxLayout="column">
    <div fxFlex="60">
      <mat-form-field appearance="outline" fxFlex>
        <input matInput type="email" name="email" placeholder="Email Address" [(ngModel)]="email" (keyup.enter)="submit()" />
        <mat-icon matPrefix>mail_outline</mat-icon>
      </mat-form-field>
    </div>

    <div fxFlex="40">
      <!-- <p *ngIf="ackMessage || errorMessage; else defaultMessage;">{{ackMessage}} -->
      <mat-error *ngIf="errorMessage">{{ errorMessage }}</mat-error>
      <!-- </p> -->
      <!-- <ng-template #defaultMessage> -->
      <p *ngIf="!errorMessage">You'll receive password recovery steps on your registered email.</p>
      <!-- </ng-template> -->
    </div>
  </mat-card-content>

  <mat-card-actions fxLayoutAlign="end center">
    <button mat-button routerLink="/auth/login">BACK TO LOGIN</button>
    <div fxFlex></div>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="disableSubmitAction">SUBMIT</button>
    <button mat-button (click)="reset()">RESET</button>
  </mat-card-actions>
</mat-card>
<mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>

<ng-template #showAcknowledgement>
  <mat-card fxLayout="column" fxLayoutGap="40px">
    <mat-card-header>
      <mat-icon matCardAvatar color="primary">check_circle</mat-icon>
      <div class="card-title">Confirmation</div>
    </mat-card-header>
    <mat-card-content>
      <p>{{ ackMessage }}</p>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end center">
      <a mat-raised-button color="primary" routerLink="/auth/login">OK</a>
    </mat-card-actions>
  </mat-card>
</ng-template>
