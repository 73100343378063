import { AuthService } from '../auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mlc-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  email: string;
  disableSubmitAction = false;
  ackMessage: string;
  errorMessage: string;
  showProgress = false;

  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.reset();
  }

  reset(): void {
    this.email = '';
    this.disableSubmitAction = false;
    this.ackMessage = '';
    this.errorMessage = '';
  }

  submit(): void {
    this.showProgress = true;
    if (!this.email) { this.showProgress = false; return; }
    this.disableSubmitAction = true;
    this.ackMessage = '';
    this.errorMessage = '';
    this.auth.sendPasswordResetEmail(this.email)
      .then(() => {
        this.ackMessage = 'Please follow password reset steps sent to your registered email address.';
        this.disableSubmitAction = false;
        this.showProgress = false;
      })
      .catch((err) => {
        this.errorMessage = 'Oops! Invalid email or your account is not active!';
        this.disableSubmitAction = false;
        this.showProgress = false;
      });
  }

}
