import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mlc-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  dateValue: Date;
  constructor() { }

  ngOnInit() {
    this.dateValue = new Date();
  }

}
