import { NgModule } from '@angular/core';
import { canActivate, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { JoinOrgComponent, LoginComponent, RegisterComponent, ResetPasswordComponent } from './auth';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/auth/login']);
const redirectLoggedInToAppDashboard = () => redirectLoggedInTo(['/app']);

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'join', component: JoinOrgComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
    ],
    // FIXME: Pending redirection of logged in to Dashboard causes error on logout navigation
    // ...canActivate(redirectLoggedInToAppDashboard),
  },

  // Lazy loaded Main-App module - all other lazy routes are managed inside Main-App module
  {
    path: 'app',
    loadChildren: () => import('./main-app/main-app.module').then((m) => m.MainAppModule),
    ...canActivate(redirectUnauthorizedToLogin),
  },

  // Any other mismatching route
  { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/auth/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
